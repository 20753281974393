@media print {
  .no-print,
  .btn,
  .fa-pencil-alt,
  .pagination-bottom,
  .no-print *,
  input,
  .delete,
  .table-count {
    display: none !important;
  }

  .card {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }

  ._hj_feedback_container {
    display: none !important;
  }
}

