@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 0.875rem;
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  /* Firefox */
}

// Social login
.google-login [role="button"],
.google-login iframe div[role="button"] {
  @apply rounded-full px-4;
  span {
    font-family: Montserrat !important;
    font-weight: 500 !important;
    color: black !important;
  }
}

/* HELPERS */
.restricted {
  float: left;
  margin-left: 20px;
  margin-top: 20px;
}

.toasts {
  position: absolute;
  z-index: 1080;
  top: 0px;
  right: 10px;
  padding: 1px 11px 0;
}

.delete {
  @apply text-red;
  cursor: pointer;

  &.right {
    margin-right: 8px;
  }

  &:hover {
    @apply text-red-dark !important;
  }
}

/* ICONS */
.heading-icon-small {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  padding: 3px 0;
  text-align: center;
  margin-right: 15px;
  margin-bottom: 10px;
  display: inline-block;
}

.key-icon {
  @apply text-yellow bg-yellow-light border border-yellow-light;
}

/* IMPERSONATION */

.account-on-hold {
  margin-bottom: 44px;

  .account-on-hold-container {
    @apply bg-red-light;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    padding: 10px 35px;

    svg {
      @apply text-red !important;
    }

    .account-on-hold-text {
      font-weight: bold;
      margin-left: 10px;
      @apply text-red !important;
    }

    a {
      float: right;
      @apply text-red !important;
      text-decoration: underline !important;
    }
  }

  &.inline {
    margin-bottom: 0;
    width: 100%;

    .account-on-hold-container {
      position: relative;
    }
  }
}

/* TABLES */
table {
  thead {
    @apply bg-primary-light;
  }

  th {
    @apply bg-primary-light text-primary;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 10px 3px 10px;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 13px !important;
    letter-spacing: 0.07em;
    border-left: none !important;
    border-right: none !important;
  }
}

.saving-overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
}

/* MAP */
.color-picker-button {
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: relative;
  left: 3px;
  border-radius: 3px;
}

.color-picker {
  position: relative;
  left: -10px;
  top: 13px;
  margin-bottom: 30px;

  input {
    height: 30px !important;
    width: 90px !important;
  }
}

/* INFINITE SCROLL */
.infinite-scroll-component {
  &.has-items {
    @apply border border-gray-light rounded-md;
  }
}

.infinite-scroll-component :nth-child(1) {
  border-top: 0;
}

/* DROPZONE */
.dropzone {
  @apply border-dashed border-2 border-primary bg-white text-primary cursor-pointer;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* ORDERED LISTS */
ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

@import "print.scss";
