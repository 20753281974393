.maintenance {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);

  .card {
    padding: 30px 100px;

    img {
      max-width: 680px;
      padding: 70px 50px 30px;
    }
  }
}
